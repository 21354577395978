<template>
  <div id="app" class="tw-bg-grey-200 min-height-cls">
    <div class="tw-container tw-mx-auto lg:tw-pr-2 tw-my-2" v-if="error" ref="error">
      <div class="tw-bg-form-error-bg tw-p-2 tw-rounded-md tw-w-full md:tw-w-7/12 lg:tw-w-8/12 xl:tw-w-9/12 md:tw-mr-1">
        <h3>{{ error.status.message }}</h3>
      </div>
    </div>
    <div class="tw-container tw-mx-auto md:tw-flex">
      <div class="tw-relative tw-w-full md:tw-w-7/12 lg:tw-w-8/12 xl:tw-w-9/12 md:tw-mr-1 tw-px-1 lg:tw-px-0">
        <loading-overlay color="red-500" class="tw-text-3xl" v-if="loading" />

        <tipp v-if="data.headerTip" open class="tw-mb-2" direction="bottom-left">
          <template #default>
            <div class="tw-inline" v-text="data.headerTip.headline"></div>
          </template>
          <template #toggle open>
            <div class="tw-inline" v-html="base64Decode(data.headerTip.subline)"></div>
          </template>
        </tipp>

        <div class="tw-flex tw-flex-wrap">
          <div class="tw-w-full tw-px-2 tw-py-2 tw-text-white tw-flex tw-justify-center tw-bg-red-500" v-if="getBigBanner()">
            <p><strong v-html="getBigBanner()"></strong></p>
          </div>

          <Countdown :date="data.countdown.date" :pluralDayText="data.countdown.dayText" :prefixText="data.countdown.text" :postfixText="data.countdown.trailingText" :backgroundColor="data.countdown.color" :slideText="data.countdown.slideText" v-if="data.countdown"></Countdown>
          <section class="tw-flex tw-w-full" v-if="jahreslosData">
            <tab v-if="jahreslosData.header.leftTab" v-bind="jahreslosData.header.leftTab" />
            <tab v-if="jahreslosData.header.rightTab && !(data.lotType === LotType.VoucherLot && data.editModeEnabled == true)" v-bind="jahreslosData.header.rightTab" />
          </section>

          <section class="tw-w-full tw-px-2 lg:tw-px-4 tw-w-full tw-bg-white tw-text-gray-800 tw-mb-1/4 tw-py-2" v-if="changeLotData">
            <tipp class="tw-mb-2" direction="bottom-left">
              <template #default>
                {{ changeLotData.lotConfigurationNoticeHeadline }}
              </template>
            </tipp>
            <p>{{ changeLotData.htmlLotConfigurationNoticeSubline }}</p>
          </section>
          <template v-for="(block, index) in sections">
            <section class="tw-w-full tw-px-2 lg:tw-px-4" :class="{
                'tw-w-full lg:tw-flex-1 tw-bg-white':
                  !boosterData && block.name === 'Super-Gewinn',
                'tw-w-full lg:tw-flex-1 lg:tw-mr-1/4 tw-bg-darkRed-500 tw-text-white':
                  !boosterData && block.name === 'Basislos',
                'tw-w-full tw-bg-white tw-text-gray-800':
                  !boosterData &&
                  block.name !== 'Basislos' &&
                  block.name !== 'Super-Gewinn',
                'tw-bg-white': boosterData,
                'tw-mb-1/4 tw-py-2': isVisible(block),
              }" v-if="isVisible(block)" :key="index">
              <LotnumberPicker :config="block" :product="data.product" :readonly="data.editModeEnabled" :sectionIndex="index" :initialLotnumber="
                  data.editModeEnabled ? data.lotNumber : undefined
                " v-if="block.type == 'LotNumberPicker'" />

              <Drawing :hasBestseller="bestsellerData" :drawing="block" :sectionIndex="index" v-if="block.type == 'WinningCategory'" :drawingMaxCount="drawingMaxCount" :open-bonus-scheme-index="openBonusSchemeIndex" :bestsellerActive="bestsellerActive" @toggleBonusScheme="toggleBonusScheme($event)" @toggleBestseller="toggleBestsellerBySlider" />
              <PlayingDateSelector v-bind="block" v-if="
                  block.type == 'PlayingDateSelector' &&
                  data.lotType === LotType.GiftLot
                " @change="
                  changePlayingDate($event);
                  trackPlayingDateChange();
                ">
                <template #headline>
                  <span v-if="jahreslosData" class="section-index">{{ index + 1 }}.</span>
                  {{ block.headline }}
                </template>
              </PlayingDateSelector>

              <LotPropertySelector id="LotFormat" ref="lotFormat" errorMessage="Bitte wähle wie du das Los übergeben möchtest." v-bind="lotFormatSelectorProps(block)" :selected-property="data.lotFormat" @change="
                  changeLotFormat($event);
                  trackLotFormatChange($event);
                " v-if="block.type == 'LotFormatSelector'">
                <template #headline>
                  <span v-if="jahreslosData" class="section-index">{{ index + 1 }}.</span>
                  {{ block.headline }}
                </template>

                <template v-if="block.modal" #modal-link="{ linkText, headline, lotProperties }">
                  <lot-format-modal :link-text="linkText" :headline="headline" :lot-properties="lotProperties" @change-lot-format="changeLotFormat($event)" />
                </template>
              </LotPropertySelector>

              <LotPropertySelector id="LotType" ref="lotType" errorMessage="Bitte entscheide, wer den Mitspielbeginn festlegt." v-bind="lotTypeProps(block)" :selected-property="data.lotType" @change="
                  changeLotType($event);
                  trackLotTypeChange($event);
                " v-if="block.type == 'LotTypeSelector'">
                <template #headline>
                  <span v-if="jahreslosData" class="section-index">{{ index + 1 }}.</span>
                  {{ block.headline }}
                </template>

                <template v-if="block.modal" #modal-link="{ linkText, headline, content, buttonText }">
                  <lot-type-modal :content="content" :button-text="buttonText" :link-text="linkText" :headline="headline" @change-lot-type="changeLotType($event)" />
                </template>
              </LotPropertySelector>

              <LotPropertySelector v-bind="lotDurationProps(block)" :selected-property="data.lotDuration" v-if="block.type == 'RuntimeSelector'" @change="
                  changeLotDuration($event);
                  trackLotDurationChange($event);
                ">
                <template #headline>
                  <span v-if="jahreslosData" class="section-index">{{ index + 1 }}.</span>
                  {{ block.headline }}
                </template>
              </LotPropertySelector>

              <VideoGreetingSelector v-if="
                  block.type === 'VideoGreeting' &&
                  data.lotType === LotType.VoucherLot
                " v-bind="videoGreetingProps(block)" @change="changeVideoGreeting($event)" @change-selector="trackVideoGreetingChange($event)">
                <template #headline>
                  <span>{{ index + 1 }}.</span>
                  {{ block.selectionHeadline }}
                </template>
              </VideoGreetingSelector>

              <template v-if="block.type === 'LotRecipientSelector'">
                <RecipientSelector v-if="data.lotType === LotType.GiftLot" :config="block">
                  <template v-if="data.lotType === LotType.GiftLot" #headline>
                    <span v-if="jahreslosData" class="section-index">{{ index + 1 }}.</span>
                    {{ block.giftHeadline }}
                  </template>

                  <template v-if="data.lotType === LotType.GiftLot" #subline>
                    {{ block.giftSubline }}
                  </template>

                  <template #form>
                    <RecipientForm ref="form" :config="block" />
                  </template>
                </RecipientSelector>
              </template>

              <LotPropertySelector v-if="block.type === 'ShippingTargetSelector'" v-bind="lotShippingTargetProps(block)" :selected-property="data.lotShippingTarget" @change="
                  changeLotShippingTarget($event);
                  trackLotShippingTargetChange($event);
                ">
                <template #headline>
                  <span v-if="jahreslosData" class="section-index">{{ index + 1 }}.</span>
                  <template v-if="data.lotType === LotType.VoucherLot">
                    {{ block.voucherShippingHeadline }}
                  </template>
                  <template v-else>
                    {{ block.giftShippingHeadline }}
                  </template>
                </template>

                <template #additional>
                  <template v-if="
                      data.lotShippingTarget === 'LotOwner' &&
                      data.lotType !== LotType.GiftLot
                    ">
                    <h3 class="tw-mt-3 tw-text-base font-bold">
                      {{ block.voucherPresenteeShippingHeadline }}
                    </h3>

                    <RecipientForm ref="form" :config="block" />
                  </template>

                  <template v-if="data.lotShippingTarget === 'LotOwner'">
                    <Datepicker :label="block.shippingDateDropdownLabel" />
                  </template>
                </template>
              </LotPropertySelector>
            </section>

            <template v-if="!data.editModeEnabled">
              <div :key="'tip' + index" v-if="index === 0 && bestsellerData && bestsellerData.tipLabel && bestsellerData.tipLabel != ''" class="tw-py-2 tw-px-2 lg:tw-px-4 tw-flex tw-items-start tw-w-full tw-justify-between tw-text-white tw-bg-orange-500">
                <strong>{{ bestsellerData.tipLabel }}</strong>
              </div>

              <!-- Insert the div after the first section -->
              <div :key="'bestseller' + index" v-if="index === 0 && bestsellerData" class="tw-py-2 tw-px-4 tw-flex tw-items-start tw-w-full tw-justify-between tw-text-grey-800" :class="{
                  'tw-bg-green-100 tw-text-grey-800': bestsellerActive,
                  'tw-bg-orange-100 tw-text-grey-800': !bestsellerActive
                }">
                <div class="tw-flex tw-flex-col" @click="toggleTextVisibility">
                  <span>
                    <strong>{{ bestsellerData.boxHeadline }}</strong>
                    {{ bestsellerData.boxSubline }}
                    <span class="tw-align-middle tw-icon text-l tw-ml-1 lotconfigurator__bestseller__text" :class="{
                        'tw-text-red-500 tw-border tw-border-red-500 tw-rounded-full':
                          hovered,
                        'tw-icon-weiter-top-bold': isTextVisible,
                        'tw-icon-weiter-down-bold': !isTextVisible,
                      }" @mouseover="hovered = true" @mouseleave="hovered = false">
                    </span>
                  </span>
                  <span class="tw-mt-2" v-if="isTextVisible">
                    Bei den 16 Ziehungen im Monat spielt dein Los beim
                    Sofortgewinn, Kombigewinn und Dauergewinn mit. Damit sicherst
                    du dir die maximale Gewinnchance – sogar im Zusatzspiel.
                  </span>
                </div>
                <ToggleSwitchVue :value="bestsellerActive" :color="bestsellerActive ? 'green' : 'gray'" @input="toggleBestseller">
                  <template v-slot:label>
                    {{
                      bestsellerActive
                        ? bestsellerData.buttonActivatedLabel
                        : bestsellerData.buttonLabel
                    }}
                  </template>
                </ToggleSwitchVue>
              </div>
            </template>
          </template>
        </div>
      </div>

      <sticky-container class="tw-w-full md:tw-w-4/12 lg:tw-w-4/12 xl:tw-w-3/12 md:tw-ml-1" :disabled="['xs', 'sm'].includes($bp.name)">
        <div class="tw-relative">
          <loading-overlay color="red-500" class="tw-text-3xl" v-if="loading" />
          <Summary @error="handleError" @loading="loading = true" @done="loading = false" @validate-form="validateAndProceed($event)" :loading="loading" :drawing="data.categoryAreaContent" :lotFormat="data.lotFormat" :playingDate="
              data.lotType === LotType.VoucherLot
                ? PlayingDateUnspecified
                : data.playingDate
            " :shippingDate="data.shippingDate" />
        </div>
      </sticky-container>
    </div>
    <div class="tw-container tw-mx-auto md:tw-flex foot-note">
      <div class="tw-mx-1 lg:tw-mx-0 tw-w-full md:tw-w-7/12 lg:tw-w-8/12 xl:tw-w-9/12">
        <div class="tw-mt-2 tw-flex">
          <span class="asterisk" v-if="data.legalNotice" v-html="data.legalNotice"></span>
        </div>
        <p class="tw-pl-2 tw-mt-1/2 winning-chance-notice" v-if="data.winningChanceLegalNotice" v-text="data.winningChanceLegalNotice"></p>
        <div class="tw-pl-2 tw-mb-2 tw-mt-2" v-if="data.svlData != null">
          <a v-if="data.svlData.Texts.AsteriskAccordionHeadline" href="#" :class="{ 'active': footNoteOpen }" @click.prevent="toggleFootNote" v-html="data.svlData.Texts.AsteriskAccordionHeadline"></a>
          <p v-if="data.svlData.Texts.AsteriskAccordionHeadline" v-show="footNoteOpen" v-html="data.svlData.Texts.AsteriskAccordionText"></p>
          <div v-if="data.svlData.Texts.AsteriskText" v-html="data.svlData.Texts.AsteriskText"></div>
        </div>
      </div>
    </div>
    <!--    <KitchenSink></KitchenSink>-->
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Drawing from "./components/Drawings/Drawing.vue";
import Summary from "./components/Summary.vue";
import LotnumberPicker from "./components/LotnumberPicker.vue";
import LotPropertySelector from "./components/LotPropertySelector.vue";
import StickyContainer from "./components/StickyContainer.vue";
import { getData } from "./services/edit-mode-service";
import { getSvlData } from "./services/svl-service";
import LoadingOverlay from "./components/LoadingOverlay.vue";
import Tab from "./components/Tab.vue";
import LotFormatModal from "./components/Jahreslos/LotFormatModal.vue";
import LotTypeModal from "./components/Jahreslos/LotTypeModal.vue";
import PlayingDateSelector from "./components/Jahreslos/PlayingDateSelector.vue";
import RecipientSelector from "./components/Jahreslos/RecipientSelector.vue";
import RecipientForm from "./components/Jahreslos/RecipientForm.vue";
import VideoGreetingSelector from "./components/Jahreslos/VideoGreetingSelector.vue";
import Countdown from "./components/Countdown.vue";
import { LotType, ShippingTarget, LotDuration } from "@/constants";
import ToggleSwitchVue from "./components/ToggleSwitch.vue";
// import KitchenSink from "./KitchenSink.vue";

export default {
  name: "App",
  components: {
    Drawing,
    Summary,
    LotPropertySelector,
    LotnumberPicker,
    StickyContainer,
    LoadingOverlay,
    Tab,
    LotFormatModal,
    LotTypeModal,
    PlayingDateSelector,
    RecipientSelector,
    RecipientForm,
    Datepicker: () => import("./components/Datepicker"),
    VideoGreetingSelector,
    Countdown,
    ToggleSwitchVue,
    // KitchenSink
  },
  beforeUnmount() {
    this.$root.$off("openModal", this.openModal);
    this.$root.$off("closeModal", this.closeModal);
  },
  computed: {
    drawingMaxCount: function () {
      const drawings = this.data.categoryAreaContent.filter(
        (content) => content.type === "WinningCategory"
      );
      let levelMax = [];
      drawings.forEach((drawing) => levelMax.push(drawing.levels.length));
      return Math.max(...levelMax);
    },
    shippingTargetSectionIndex() {
      if (
        this.data.lotType === LotType.VoucherLot &&
        this.data.lotFormat === "HighQualityPrinted"
      ) {
        return 5;
      }

      return 6;
    },
    videoGreetingSectionIndex() {
      if (this.data.lotType === LotType.GiftLot) {
        return 7;
      }

      return 5;
    },
    playingDateSectionIndex() {
      if (this.data.lotFormat === "Pdf") {
        return 6;
      }
      return 7;
    },
    sections() {
      if (!this.jahreslosData) {
        return this.data.categoryAreaContent;
      }

      const sections = this.data.categoryAreaContent.slice();

      if (
        this.data.lotFormat === "HighQualityPrinted" &&
        this.data.lotOwner !== LotType.PrivateLot
      ) {
        const recipientSelectorIndex = sections.findIndex(
          (s) => s.type === "LotRecipientSelector"
        );
        sections.splice(
          sections.findIndex((s) => s.type === "LotRecipientSelector") + 1,
          0,
          {
            ...sections[recipientSelectorIndex],
            type: "ShippingTargetSelector",
          }
        );
      }

      return sections.filter((section) => this.isVisible(section));
    },
  },
  async created() {
    this.$root.$on("openModal", this.openModal);
    this.$root.$on("closeModal", this.closeModal);

    const appElement = document.querySelector('#app');

    if (appElement) {
      const orderPosition = appElement.getAttribute('data-order-position');
      const editMode = appElement.getAttribute('data-edit-mode');

      if (editMode === "true") {
        this.data.orderPosition = orderPosition;
        this.data.editModeEnabled = true;
      } else {
        this.data.editModeEnabled = false;
      }
    }

    const svl = await getSvlData(this.data.product);
    this.data.svlData = svl.data;

    this.$nextTick(() => {
      this.getCurrentSelections();
    });

    this.data.categoryAreaContent.forEach((category) => {
      if (category.levels) {
        const breakpointBelowMargin = category.mobilePreselectionMargin &&
          this.$bp.lte(category.mobilePreselectionMargin);

        category.levels.forEach((level) => {
          if (breakpointBelowMargin) {
            level.isSelected = level.isSelectedMobile;
          }

          if (level.isSelected) {
            this.preSelectedProductMatrixValues.push(level.productMatrixValue);
          }
        });
      }
    });

    if (!this.data.editModeEnabled) {
      this.loading = false;
      return;
    }

    // If the edit mode is enabled we need to retrieve the product configuration
    const result = await getData(this.data.product, this.data.orderPosition);

    const lotConfiguration = result.data.lotConfiguration;

    this.data.shippingDate = result.data.shippingDate;
    this.data.playingDate = result.data.playingDate;
    this.data.lotNumber = result.data.lotNumber;
    this.data.lotFormat = result.data.lotFormat;

    if (
      this.data.editModeEnabled &&
      result.data.lotOwner === LotType.PrivateLot &&
      this.data.lotType === LotType.GiftLot
    ) {
      // The user edits a PrivateLot but decided to make it a GiftLot
      this.data.lotType = LotType.GiftLot;
    } else if (
      this.data.editModeEnabled &&
      result.data.lotOwner === LotType.GiftLot &&
      this.data.lotType === LotType.PrivateLot
    ) {
      // The user edits a GiftLot but decided to make it a PrivateLot
      this.data.lotType = LotType.PrivateLot;
    } else {
      this.data.lotType = result.data.lotOwner;
    }
    this.data.lotShippingTarget = result.data.jlShippingTarget;
    this.data.lotDuration =
      (lotConfiguration && lotConfiguration["Attribute6"]) || "LZ1J";
    this.data.recipient = result.data.jlAddress || { country: "D" };

    this.data.videoGreeting = result.data.jlVideoGreeting;

    this.mapLotConfiguration(lotConfiguration);
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      error: null,
      openBonusSchemeIndex: null,
      bestsellerActive: false,
      initialConfig: null,
      preSelectedProductMatrixValues: [],
      isTextVisible: false,
      hovered: false,
      footNoteOpen: false
    };
  },
  methods: {
    getBigBanner() {
      return this.$options.filters.capitalize(this.data.svlData?.Texts?.BigBanner || '');
    },
    toggleFootNote() {
      this.footNoteOpen = !this.footNoteOpen;
      console.log(this.footNoteOpen);
    },
    toggleTextVisibility() {
      this.isTextVisible = !this.isTextVisible;
    },
    getCurrentSelections() {
      const currentSelections = {};
      this.data.categoryAreaContent.forEach((category) => {
        if (category.levels) {
          category.levels.forEach((level) => {
            if (level.isSelected) {
              currentSelections[`Attribute${level.index}`] =
                level.productMatrixValue;
            } else {
              currentSelections[`Attribute${level.index}`] = undefined;
            }
          });
        }
      });

      return currentSelections;
    },

    toggleBestsellerBySlider() {
      if (this.bestsellerActive) {
        //Tracking bei Toggle
        this.trackClick(
          "pk_lotterie-mgl-konfi_bestseller_checkbox_deaktivieren"
        );
        this.bestsellerActive = !this.bestsellerActive;
      }
    },

    toggleBestseller() {
      if (this.bestsellerActive) {
        // Unselect all levels
        this.data.categoryAreaContent.forEach((category) => {
          if (category.type === "WinningCategory") {
            category.levels.forEach((level) => {
              level.isSelected = false;
            });
          }
        });

        // Reselect only those with matching productMatrixValue
        this.data.categoryAreaContent.forEach((category) => {
          if (category.levels) {
            category.levels.forEach((level) => {
              if (
                this.preSelectedProductMatrixValues.includes(
                  level.productMatrixValue
                )
              ) {
                level.isSelected = true;
              }
            });
          }
        });

        //Tracking bei Toggle
        this.trackClick(
          "pk_lotterie-mgl-konfi_bestseller_checkbox_deaktivieren"
        );
      } else {
        this.trackClick(
          "pk_lotterie-mgl-konfi_bestseller_checkbox_aktivieren"
        );

        this.mapLotConfiguration(this.bestsellerData.lotConfiguration);
      }
      this.bestsellerActive = !this.bestsellerActive;
    },

    hasLevel(category, attribute, value) {
      return (category.levels || []).find(
        (level) =>
          level.productMatrixAttribute === attribute &&
          level.productMatrixValue === value
      );
    },

    mapLotConfiguration(configuration) {
      // First, unselect all existing levels
      this.data.categoryAreaContent.forEach((category) => {
        if (category.type === "WinningCategory") {
          category.levels.forEach((level) => {
            level.isSelected = false;
          });
        }
      });

      // Then, select only the ones specified in the configuration
      Object.keys(configuration).forEach((attribute) => {
        this.data.categoryAreaContent.forEach((category) => {
          if (category.type === "WinningCategory") {
            const value = configuration[attribute];
            const level = this.hasLevel(category, attribute, value);

            if (level) {
              level.isSelected = !!value;
            }
          }
        });
      });
    },

    toggleBonusScheme(id) {
      if (this.openBonusSchemeIndex === id) {
        this.openBonusSchemeIndex = null;
      } else {
        this.openBonusSchemeIndex = id;
      }
    },
    base64Decode(str) {
      return decodeURIComponent(
        atob(str)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    },
    trackLotFormatChange(selectedLotFormat) {
      if (selectedLotFormat === "Pdf") {
        this.trackClick(
          "pk_lotterie_konfi_versandart_checkbox_versandart-auswaehlen_per-e-mail"
        );
      } else {
        this.trackClick(
          "pk_lotterie_konfi_versandart_checkbox_versandart-auswaehlen_per-brief"
        );
      }
    },
    changeLotFormat(newLotFormat) {
      this.data.lotFormat = newLotFormat;
    },
    trackLotShippingTargetChange(selectedLotShippingTarget) {
      if (selectedLotShippingTarget === ShippingTarget.Customer) {
        this.trackClick(
          "pk_lotterie_jl-konfi_los-verschenken_checkbox_versand_an-mich"
        );
      } else {
        this.trackClick(
          "pk_lotterie_jl-konfi_los-verschenken_checkbox_versand_an-den-beschenkten"
        );
      }
    },
    changeLotShippingTarget(newLotShippingTarget) {
      this.data.lotShippingTarget = newLotShippingTarget;
    },
    trackLotTypeChange(selectedLotType) {
      if (selectedLotType === LotType.GiftLot) {
        this.trackClick(
          "pk_lotterie_jl-konfi_los-verschenken_checkbox_mitspielbeginn-entscheiden_ich-moechte-entscheiden"
        );
      } else {
        this.trackClick(
          "pk_lotterie_jl-konfi_los-verschenken_checkbox_mitspielbeginn-entscheiden_der-beschenkte-darf"
        );
      }
    },
    changeLotType(newLotType) {
      this.data.lotType = newLotType;
    },
    changePlayingDate(date) {
      this.data.userSelectedPlayingDate = this.data.playingDate = date;
    },
    trackPlayingDateChange() {
      this.trackClick(
        "pk_lotterie_jl-konfi_los-verschenken_datum_mitspielbeginn_datum-mitspielbeginn-waehlen"
      );
    },
    changeVideoGreeting(videoGreeting) {
      this.data.videoGreeting = videoGreeting;
    },
    trackVideoGreetingChange(videoGreeting) {
      if (videoGreeting !== null) {
        this.trackClick(
          "pk_lotterie_jl-konfi_los-verschenken_checkbox_videogruss-erstellen_ja"
        );
      } else {
        this.trackClick(
          "pk_lotterie_jl-konfi_los-verschenken_checkbox_videogruss-erstellen_nein"
        );
      }
    },
    changeLotDuration(lotDuration) {
      this.data.lotDuration = lotDuration;
    },
    trackLotDurationChange() {
      this.trackClick("pk_widerruf");
    },
    handleError(error) {
      this.error = error;

      this.$trackRequestError(
        [error.data.headline, error.data.message].join(" "),
        this.$route.fullPath
      );
      this.$nextTick(() => {
        this.$refs.error.scrollIntoView({ behavior: "smooth" });
      });
    },
    lotFormatSelectorProps(block) {
      const interpolationContext = {
        versandtermin: this.$formatDate(this.data.shippingDate, "de-DE"),
      };

      return {
        label: block.label,
        modal: block.modal && {
          linkText: block.modal.linkText,
          headline: block.modal.headline,
        },
        defaultTipHtml: block.defaultTipText,
        lotProperties: [
          {
            value: "Pdf",
            headline: block.pdfHeadline,
            subline: this.$interpolate(block.pdfSubline, interpolationContext),
            dataQa: "Configurator_LotFormat_InputContainer_Pdf",
            tipHtml: block.pdfTipText,
            modal: block.modal && {
              headline: block.modal.pdfHeadline,
              imageUrl: block.modal.pdfImageUrl,
              content: block.modal.pdfContent,
              buttonText: block.modal.pdfButtonText,
            },
          },
          {
            value: "HighQualityPrinted",
            headline: block.printHeadline,
            subline: this.$interpolate(
              block.printSubline,
              interpolationContext
            ),
            tipHtml: block.printTipText,
            modal: block.modal && {
              headline: block.modal.printedHeadline,
              imageUrl: block.modal.printedImageUrl,
              content: block.modal.printedContent,
              buttonText: block.modal.printedButtonText,
            },
            dataQa: "Configurator_LotFormat_InputContainer_HighQualityPrinted",
          },
        ],
        selectedProperty: block.preselection,
      };
    },
    lotTypeProps(block) {
      const lotProperties = [];
      let selectedProperty = block.preselection;

      if (block.voucherSelector) {
        if (
          !this.data.editModeEnabled ||
          (this.data.editModeEnabled &&
            this.data.lotType === LotType.VoucherLot)
        ) {
          lotProperties.push({
            value: LotType.VoucherLot,
            headline: block.voucherSelector.headline,
            subline: block.voucherSelector.subline,
            dataQa: "activation--voucherlot",
          });
        }
      }

      if (block.giftSelector) {
        if (
          !this.data.editModeEnabled ||
          (this.data.editModeEnabled && this.data.lotType === LotType.GiftLot)
        ) {
          lotProperties.push({
            value: LotType.GiftLot,
            headline: block.giftSelector.headline,
            subline: block.giftSelector.subline,
            dataQa: "activation--giftlot",
          });
        }
      }

      if (lotProperties.length === 1) {
        selectedProperty = lotProperties[0].value;
      }

      return {
        label: block.label,
        modal: block.modal,
        defaultTipHtml: block.defaultTipText,
        videogreetingTip: block.videogreetingTipText,
        lotProperties,
        selectedProperty,
      };
    },
    lotDurationProps(block) {
      return {
        lotProperties: [
          {
            value: LotDuration.OneYear,
            headline: block.fixedHeadline,
            subline: block.fixedText,
            dataQa: "duration--oneyear",
          },
          {
            value: LotDuration.Unlimited,
            headline: block.recurringHeadline,
            subline: block.recurringText,
            dataQa: "duration--unlimited",
          },
        ],
        selectedProperty: block.preselection,
      };
    },
    lotShippingTargetProps(block) {
      let label =
        this.data.lotFormat === LotType.VoucherLot
          ? block.voucherShippingSubline
          : block.giftShippingSubline;

      let lotOwnerHeadline = block.voucherSelfHeadline;
      let lotOwnerSubline = block.voucherSelfSubline;
      let customerHeadline = block.voucherPresenteeHeadline;
      let customerSubline = block.voucherPresenteeSubline;

      if (this.data.lotType === LotType.GiftLot) {
        lotOwnerHeadline = block.giftSelfHeadline;
        lotOwnerSubline = block.giftSelfSubline;
        customerHeadline = block.giftPresenteeHeadline;
        customerSubline = block.giftPresenteeSubline;
      }

      return {
        label,
        lotProperties: [
          {
            value: ShippingTarget.Customer,
            headline: lotOwnerHeadline,
            subline: lotOwnerSubline,
            dataQa: "Checkout_ShippingTargetSelection_InputContainer_LotOwner",
          },
          {
            value: ShippingTarget.LotOwner,
            headline: customerHeadline,
            subline: customerSubline,
            dataQa: "Checkout_ShippingTargetSelection_InputContainer_Customer",
          },
        ],
        selectedProperty: null,
      };
    },
    videoGreetingProps(block) {
      return {
        senderTextLabel: block.senderTextLabel,
        recipientTextLabel: block.recipientTextLabel,
        greetingTextLabel: block.greetingTextLabel,
        subline: block.selectionSubline,
        label: block.selectionLabel,
        modal: block.modal && {
          linkText: block.modal.linkText,
          headline: block.modal.headline,
          content: block.modal.content,
          activateButtonText: block.modal.activateButtonText,
          deactivateButtonText: block.modal.deactivateButtonText,
        },
        videos: block.videos,
        videoHeadline: block.videosHeadline,
        contentHeadline: block.textsHeadline,
        initialVideoId:
          this.data.videoGreeting && this.data.videoGreeting.videoTemplateId,
      };
    },
    isVisible(block) {
      if (
        block.type === "PlayingDateSelector" &&
        this.data.lotType !== LotType.GiftLot
      ) {
        return false;
      }

      if (
        block.type === "LotRecipientSelector" &&
        this.data.lotType === LotType.VoucherLot
      ) {
        return false;
      }

      if (
        block.type === "VideoGreeting" &&
        this.data.lotType !== LotType.VoucherLot
      ) {
        return false;
      }

      return true;
    },
    openModal(scrollable) {
      disableBodyScroll(scrollable, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute("body-scroll-lock-ignore") !== null) {
              return true;
            }

            el = el.parentElement;
          }
        },
      });
    },
    closeModal(scrollable) {
      enableBodyScroll(scrollable);
    },
    async validateAndProceed(proceedCallback) {
      const formValid =
        !this.$refs.form || this.$refs.form.length < 1
          ? true
          : await this.$refs.form[0].checkValid();
      const lotFormatValid = this.$refs.lotFormat
        ? await this.$refs.lotFormat[0].checkValid()
        : true;
      const lotTypeValid = this.$refs.lotType
        ? await this.$refs.lotType[0].checkValid()
        : true;

      if (formValid && lotFormatValid && lotTypeValid) {
        return proceedCallback(true);
      }

      if (!lotFormatValid) {
        this.$refs.lotFormat[0].$el.scrollIntoView({ behavior: "smooth" });
      } else if (!lotTypeValid) {
        this.$refs.lotType[0].$el.scrollIntoView({ behavior: "smooth" });
      } else if (!formValid) {
        this.$refs.form[0].$el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss">
/* purgecss start ignore */

@import '../../master/src/sass/core/fonts';
//TODO: remove + use tailwind classes only
@import '../../master/src/sass/core/type';

// Lotconfigurator Styles
@import 'sass/type.scss';
@import 'sass/lotconfigurator.scss';

// IE11 compatibility
//
// Unfortunatly IE11 does not support CSS variables, but the
// tailwind transform utilities rely on them
//
_:-ms-fullscreen,
:root .tw--translate-y-1\/2 {
  transform: translateY(-50%);
}

.loading-spinner {
  font-size: 64px;
}

.lotconfigurator {
  &__bestseller {
    &__text {
      @media (max-width: 640px) {
        &:hover,
        &:focus {
          border-color: transparent;
          color: #565656;
        }
      }
    }
  }
}

// This prevents a layout shift on mobile which leads
// to a bad Lighthouse CLS score
.min-height-cls {
  min-height: 100vh;
  @screen lg {
    min-height: 60vh;
  }
}

#app {
  // Fix issue with invisible selects
  select {
    visibility: visible;
  }

  // Disable focus outline
  button {
    &:focus {
      outline: none;
    }
  }

  sup {
    font-size: smaller;
    top: -5px;
  }

  sub {
    font-size: smaller;
    bottom: -5px;
  }

  .foot-note {
    color: #565656;

    * {
      line-height: 18px;
      font-size: 12px;
    }

    p {
      margin: 10px 0 16px 0;
    }

    a {
      display: block;
      text-decoration: underline !important;
      position: relative;
      margin-bottom: 5px;

      &:after {
        transition: all 0.3s ease-in-out;
        position: absolute;
        font-family: Icons;
        content: '';
      }

      &:hover,
      &:focus {
        color: #e31519;
      }

      &.active {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    .winning-chance-notice {
      margin-top: 4px;
    }
  }
}

//needed for sticky sidebar
.page.page--configurator {
  overflow: visible;
}
</style>
